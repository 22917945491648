@use '../../../../../styles/theme.scss' as *;

.projectInformation {
    display: flex;
    flex-direction: column;

    .projectTop {
        z-index: 1;
        height: 50px;
        border: solid 1px rgba($white, 0.4);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 0;
        gap: 5px;
        // border-radius: 3px 3px 0px 0px;

        a {
            text-decoration: none;
            color: $white;
        }

        .gitHubLogo {
            cursor: pointer;
            z-index: 3;
            height: 40px;
            width: 40px;
            background-color: rgb(231, 229, 223);
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;

            .projectIcon {
                filter: invert(0%);
                width: 70%;
            }

            &:hover {
                .projectIcon {
                    filter: invert(100%);
                }

                background-color: rgb(17, 17, 17);
            }
        }

        .projectTitle {
            cursor: pointer;
            z-index: 3;
            font-size: 1.4rem;
            margin-left: 8px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .bottom {
        display: flex;
        border: solid 1px rgba($white, 0.4);
        border-top: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        .imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px dashed rgba($white, 0.2);
            border-left: 0;
            border-top: 0;
            border-right: 0;
            padding-bottom: 15px;
            overflow-y: hidden;

            .projectImage {
                border-radius: 5px;
                height: 300px;
            }
        }

        @media screen and (max-width: 500px) {
            .imageContainer {
                .projectImage {
                    width: 300px;
                    height: 200px;
                    object-fit: cover;
                }
            }
        }

        .projTextContainer {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow-y: scroll;
            max-height: 200px;

            .readme {
                color: $white;
                font-size: 0.8rem;
                line-height: 20px;
            }
        }
    }
}