@use '../../../../styles/theme.scss' as *;

.icon {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: dashed;
  border-width: 2px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 30%);
  transform: translateY(0);
  transition: transform 0.2s ease-out, box-shadow 0.2s;

  .all-icons {
    transform: translateY(0);
    transition: transform 0.5s;
    z-index: 4;
    filter: none;
  }

  .popUpMenu {
    align-items: center;
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.8rem;
    height: 30px;
    justify-content: center;
    opacity: 0;
    padding: 1px 20px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateY(45px) scale(0.1);
    transition: transform 0.2s ease-in, opacity 0.15s ease-in;
    white-space: nowrap;
    z-index: 3;

    .popUpText {
      text-align: center;
    }

    .popUpTri {
      position: absolute;
      bottom: 0;
      margin-bottom: -6px;
      width: 0;
      height: 0;
      border-left: 9px solid rgba(0, 0, 0, 0%);
      border-right: 9px solid rgba(0, 0, 0, 0%);
      border-top: 7px solid rgba(28, 28, 28, 95%);
    }

    &.open {
      visibility: visible;
    }

    &.close {
      visibility: hidden;
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 50%) 0px 5px 8px;

    .all-icons {
      transform: translateY(-4px);
      filter: drop-shadow(rgba(255, 255, 255, 50%) 0px 5px 2px) invert(100%);
    }

    .popUpMenu {
      transform: translateY(-45px) scale(1);
      opacity: 1;
    }
  }
}

// Icon Classes 

.fa-html5,
.fa-css3-alt,
.fa-js-square,
.fa-node,
.fa-react {
  font-size: 3.3rem;
}

.fa-html5 {
  color: rgb(201, 63, 63);
}

.fa-css3-alt {
  color: rgb(47, 47, 239);
}

.fa-js-square {
  color: rgb(229, 229, 95);
}

.fa-node {
  color: rgb(62, 125, 62);
}

.fa-react {
  color: rgb(61, 207, 240);
}

.rails {
  width: 45px;
}

.jekyll {
  width: 70px;
}

.ruby {
  width: 52px;
}

.cSharp {
  width: 50px;
}

.swift {
  width: 52px;
}

.sass {
  width: 60px;
}

.vue {
  width: 50px;
}

.tailwind {
  width: 60px;
}

.nextJs {
  width: 55px;
  filter: invert(0.3);
}

.typescript {
  width: 60px;
}

.mui {
  width: 60px;
}

.prisma {
  width: 60px;
}

.graphql {
  width: 60px;
}

.shadcn {
  width: 40px;
  filter: invert(0.3) !important;

  &:hover {
    filter: invert(1) !important;
  }
}

.storybook {
  width: 45px;
}

.photoshop,
.audition, 
.premiere {
  height: 55px;
}

.ableton {
  width: 60px;
}

.unity {
  width: 65px;
}

.blender {
  width: 55px;
}

.figma {
  width: 66px;
}