@use './theme.scss' as *;

.header1 {
    margin-top: 35px;
    font-size: 34px;
    margin-bottom: 45px;
}

.header2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.header3 {
    font-size: 1rem;
    margin-bottom: 15px;
}

li {
    margin-bottom: 5px;
}

.lastLi {
    margin-bottom: 0px;
}

.paragraph {
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 10px;
    line-height: 25px;
}

@media screen and (max-width: $mobileWidth) {
    .header1 {
        margin-left: -9px;
    }
}