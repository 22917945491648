@use '../../../../styles/theme.scss' as *;

.button {
    cursor: pointer;
  
    position: relative;
    font-size: 0.9rem;
    font-weight: bolder;
    text-decoration: underline;
    background-color: $white;
    color: black;
  
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
  
    transition-property: padding-left, padding-right, padding-top, box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    border-radius: 0px;
  
    &:hover {
      box-shadow: -2px 2px 10px rgba(0, 0, 0, 60%);
    }
  
    &:active {
      background-color: black;
      color: $white;
    }
  }