@use "../../../styles/theme.scss" as *;

#typeText {
    width: 173px;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.14em solid;
    border-color: $white;
    margin-bottom: 10px;

    &:hover {
        &.red {
            border-color: $red;
        }

        &.blue {
            border-color: $blue;
        }
    }
}

.introBtns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.introduceContentContainer {
    display: flex;
    flex-direction: column;
}

.forwardType {
    width: 170px;
    animation: typing 1.5s steps(12) 1s 1 normal both, blink 1s steps(1) infinite;
}

.backspaceType {
    width: 170px;
    animation: typingBack 0.7s steps(7) 1s 1 normal both,
        blink 1s steps(1) infinite;
}

.reType {
    width: 85px;
    animation: reTyping 0.1s steps(1) 1 normal both, blink 1s steps(1) infinite;
}

.dot {
    pointer-events: none;
}

@keyframes typing {
    0% {
        width: 0px;
    }

    90% {
        width: 173px;
    }

    100% {
        width: 173px;
    }
}

@keyframes typingBack {
    0% {
        width: 171px;
    }

    90% {
        width: 73px;
    }

    100% {
        width: 73px;
    }
}

@keyframes reTyping {
    0% {
        width: 72px;
    }

    70% {
        width: 87px;
    }

    100% {
        width: 87px;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}