@use '../../../../styles/theme.scss' as *;

.hoverList {
    position: relative;

    .alertPopUp {
        cursor: default;
        display: flex;
        opacity: 0;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        pointer-events: none;
        width: 100%;
        background-color: $red;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: -55px;
        transition: opacity 0.4s ease;
        z-index: 5;

        .cardText {
            font-size: 0.8rem;
            margin-left: 3px;
            line-height: 18px;
        }

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }


        .bridge {
            position: absolute;
            width: 80%;
            height: 20px;
            bottom: 0;
            right: 0;
            margin-bottom: -10px;
        }
    }

    .companyNameText {
        position: relative;
        cursor: default;
        transition: color 0.5s ease;
    }

    .err {
        text-decoration: wavy underline;
        text-decoration-color: rgba($red, 0.5);
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        transition: text-decoration-color 0.4s ease;
    }


    &.open {
        .alertPopUp {
            opacity: 1;
        }

        .companyNameText {
            color: $red;
        }
    }
}