@use '../../../../styles/theme.scss' as *;

.projectContainer {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    .newProjectContainer {
        width: 100%;

        .viewMoreBox {
            box-sizing: border-box;
            border: 1px solid rgba($white, 0.4);
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            cursor: pointer;
            border-top: 0;
            background-color: rgba($white, 0.1);
            transition: background-color 0.15s ease-in-out;

            .paragraph {
                margin: 0;
                padding: 0;
                font-size: .7rem;
                opacity: 0.8;
            }

            &:hover {
                background-color: rgba($white, 0.6);
                color: $gray;
            }
        }
    }

    .cardText {
        font-size: 0.9rem;
        line-height: 30px;
        padding-right: 2px;
    }
}