@use '../../../../../styles/theme.scss' as *;

.minimizedCont {
    display: flex;
    border: 1px solid rgba($white, 0.4);
    transition: all 0.2s ease-in-out;

    .left,
    .right {
        cursor: pointer;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left {
        border-right: 1px solid rgba($white, 0.4);
        width: 35%;

        p {
            font-size: 1.1rem;
            text-align: center;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.02);

            p {
                text-decoration: underline;
            }
        }

        @media screen and (max-width: 600px) {
            width: 50%
        }
    }

    .right {
        width: 65%;

        p {
            font-size: 0.9rem;
        }

        @media screen and (max-width: 600px) {
            width: 50%
        }
    }
}

.minimizedContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border: 1px solid rgba($white, 0.4);
    gap: 5px;
    background-color: blue;

    .headerLeft {
        height: 100%;
        flex-wrap: nowrap;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        background: red;

        .textButton {
            cursor: pointer;
            border: none;
            position: relative;
            font-size: 1.1rem;
            font-weight: bolder;
            color: $white;
            background-color: $gray;
            padding: 9px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &:active {
                background-color: black;
                color: $white;
            }
        }
    }

    .breakParagraph {
        height: 1px;
        width: 10px;
        background-color: rgba($white, 0.3);
    }

    .headerRight {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;

        .paragraph {
            margin: 0;
        }
    }

    @media screen and (max-width: $mobileWidth) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 5px 1px;

        .breakParagraph {
            width: 100%;
        }
    }
}