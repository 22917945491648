.flex-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 15px;
}

.flex-grid > * {
    aspect-ratio: 1;
    width: 100%;
}