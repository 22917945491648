.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    max-width: 650px;
    margin-bottom: 20px;
}


.test {
    background-color: red;
}