@use './styles/styleReset.scss';
@use './styles/assets.scss';
@use './styles/theme.scss' as *;
@use './styles/globalStyles.scss';

body {
  background-color: $gray;
  margin: 0;
  width: 100%;
  font-family: JetBrains;
  color: $white;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $mobileWidth) {
  .container {
    padding: 0 9px;
    align-items: flex-start;
  }
}