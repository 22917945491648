$white: rgb(246, 245, 239);
$extraWhite: rgb(248, 248, 240);
$gray: #131313;
$darkGray: rgb(0, 0, 0);
$blue: rgb(22, 182, 177);
$red: rgb(220, 78, 78);

$swivvel: rgb(32, 71, 60);
$bedroomProducer: rgb(157, 89, 239);
$rails: rgb(193, 40, 28);
$reprise: rgb(111, 109, 241);

$mobileWidth: 650px;