.flex-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));
    width: 100%;
}

.flex-grid > * {
    aspect-ratio: 1;
    width: 100%;
    max-width: 200px;
}