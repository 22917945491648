@font-face {
    font-family: JetBrains;
    src: url("../fonts/JetBrainsMono-Bold.ttf");
}

@font-face {
    font-family: JetBrainsBold;
    src: url("../fonts/JetBrainsMonoNL-ExtraBold.ttf");
}

@font-face {
    font-family: "Nimbus";
    src: url("../fonts/RM_Nimbus.ttf");
}
